*, *:before, *:after {
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box; 
    box-sizing: border-box;
}

:root{
	--cor1: #DFAF60;
    --cor2: #0E1118;
    --cor3: #9A7240;
}

.container {
    width: 90%;
    margin: 0 auto;
    padding: 0px;
}

.session {
    margin-top: 70px;
}

h1{
    font-family: "Aclonica", sans-serif;
    font-size: 26px;
    font-weight: normal;
}

h2{
    font-family: "Urbanist", sans-serif;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.5;
}

h3{
    font-family: 'arial', sans-serif;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.5;
}

p{
    font-family: 'arial', sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5;
}

body{
    background-color: #F5F5F5;
}

.bold{
    font-weight: bold;
}

.flex{
    display: flex;
    align-items: center;
}

.noScroll{
    overflow: hidden;
}

.saiba-mais-conteudo{
    background: linear-gradient(rgba(0, 0, 0, 0.900), rgba(0,0,0,0.900));
    overflow: auto;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0px;
    z-index: 2;
    align-items: center;
    justify-content: center;
}

button{
    cursor: pointer;
    background: transparent;
    border: none;
    transition: color 0.2s;
    padding: 0px;
}

.botao{
    background-color: var(--cor2);
    color: white;
    padding: 5px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.botao:hover{
    background-color: var(--cor1);
}

.botao:hover p{
    color: black
}

.botao:hover h2{
    color: black
}

.botao2{
    background-color: var(--cor2);
    color: white;
    padding: 5px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.botao2:hover{
    background-color: var(--cor3);
}

.botao2:hover .botaoLinha{
    border: 2px solid var(--cor3);
}

.wppBotao:hover{
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.navbarBotao:hover{
    background-color: #9A7240;
    color: white;
}

.lates{
    width: auto !important;
    padding: 0px !important;
    background-color: transparent !important;
}

.changeNavbar{
    transition: 0.5s ease-in;
}

.change{
    transition: 0.3s ease-in;
}

.line{
    background-color: #DFAF60;
    height: 2px;
    width: 35px;
}

.line2{
    background-color: #0E1118;
    height: 2px;
    width: 35px;
}

img[data-src]{
    opacity: 0;
}

img[src]{
    opacity: 1;
    transition: 0.5s ease-in;
}

a{
    cursor: pointer;
    background: transparent;
    border: none;
    transition: color 0.2s;
    padding: 0px;
    text-decoration: none;
    color: black;
    display: inline-block
}

.noOverflow{
    overflow-y: hidden;
}
.menu {
    width: 35px;
    height: 3px;
    background-color: black;
    margin: 6px 0;
}

.menuS{
    cursor: pointer;
    z-index: 2;
}

.responsiveSandwich{
    display: none;
    align-items: center;
    justify-content: space-between;
    gap: 150px;
}

.responsiveSandwichMargin{
    margin-top: -20px;
}

.bar1, .bar2, .bar3 {
    width: 35px;
    height: 4px;
    background-color: #DFAF60;
    margin: 6px 0;
    transition: 0.4s;
  }
  
  /* Rotate first bar */
  .change .bar1 {
    transform: translate(0, 10px) rotate(-45deg);
  }
  
  /* Fade out the second bar */
  .change .bar2 {opacity: 0;}
  
  /* Rotate last bar */
  .change .bar3 {
    transform: translate(0, -10px) rotate(45deg);
  }

.navbarFixed{
    position: fixed !important;
    top: 0;
    margin-top: 0px !important;
    display: block !important;
    width: 100% !important;
    z-index: 1;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.navbarButtonFixed{
    width: 100% !important;
}

.buttonBody{
    background-color: var(--cor2);
    color: white;
    padding: 15px 30px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.buttonBody:hover{
    background-color: var(--corHover);
}

.notVisible{
    opacity: 0 !important;
    visibility: hidden !important;
}

.hidden{
    opacity: 0 !important;
    visibility: hidden !important;
    position: absolute;
}

.backgroundImage{
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.backgroundImage2{
    background-position: top !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.fundoLogo{
    background: linear-gradient(rgba(0, 0, 0, 0.750), rgba(0,0,0,0.750)),
    url('../../public/img/fundo-cabecalho.jpg');
}

.fundoSaibaMais{
    background: linear-gradient(rgba(0, 0, 0, 0.750), rgba(0,0,0,0.750)),
    url('../../public/img/fundo-cabecalho.jpg');
}

.escritorioImagem{
    background-image: url('../../public/img/fundo-cabecalho.jpg');
}

.card1{
    background: linear-gradient(rgba(0, 0, 0, 0.800), rgba(0,0,0,0.800)),
    url('../../public/img/martelo.jpg');
}

.card2{
    background: linear-gradient(rgba(0, 0, 0, 0.800), rgba(0,0,0,0.800)),
    url('../../public/img/estatua.jpg');
}

.card3{
    background: linear-gradient(rgba(0, 0, 0, 0.800), rgba(0,0,0,0.800)),
    url('../../public/img/livro.jpg');
}

.pessoa1{
    background-image: url('../../public/img/joaoLucas.jpg');
}

.pessoa2{
    background-image: url('../../public/img/samuel.jpg');
}

.pessoa3{
    background-image: url('../../public/img/geisa.jpg');
}

@media only screen and (max-width: 871px){

    /* header */

    .responsiveSandwich{
        display: flex !important;
    }
    
    .responsiveSandwich img{
        width: 100px;
    }

    .fundoLogo{
        height: 100vh;
    }

    .fundoLogo img{
        width: 350px !important;
    }

    .navbarButton{
        display: none !important;
    }

    .navbarFixed{
        background-color: #0E1118 !important;
    }

    /* escritorio */

    .escritorioImgMoldura{
        display: none;
    }

    /* principios */

    .principiosCardsResponsivo{
        flex-direction: column;
        gap: 20px !important;
        margin-top: -710px !important
    }

    .principiosFundoResponsivo{
        height: 950px !important;
        clip-path: polygon(0% 100%, 0% 0%, 100% 6%, 100% 100%) !important;
    }

    /* equipe */

    .equipeFundoMolduraResonsivo{
        flex-direction: column;
        gap: 30px;
    }

    .equipeContentResponsivo{
        margin-top: -946px !important;
        height: 1505px !important;
        clip-path: polygon(0 6%, 100% 0%, 100% 100%, 0% 100%) !important;
    }

    /* instagram */

    .intagramPublsResponsivo{
        flex-direction: column;
        gap: 20px;
    }

    .intagramPublResponsivo{
        width: 100% !important;
    }

    /* contato */

    .contatoTextFormResponsivo{
        flex-direction: column;
        text-align: center;
        align-items: center;
        gap: 30px !important;
        justify-content: center;
    }

    .contatoFormResponsivo{
        width: 100% !important;
    }

    .contatoIconeTextoResponsivo{
        flex-direction: column;
    }

    .contatoRedesSociaisResponsivo{
        justify-content: center;
    }

    /* equipe */

    .fundoSaibaMais{
        flex-direction: column !important;
        height: auto !important;
    }

    .imgMolduraResponsivo{
        margin-top: 600px;
    }

    .equipeSaibamaisResponsivo{
        margin-bottom: 100px;
    }

}

@media only screen and (min-width: 872px) and (max-width: 1015px){

    /* header */
    .navbarTextoResponsivo{
        padding: 0px 20px !important;
    }

    /* escritorio */
    .escritorioContentResponsivo{
        gap: 40px !important;
    }

    /* equipe */

    .fundoSaibaMais{
        flex-direction: column !important;
        height: auto !important;
    }

    .imgMolduraResponsivo{
        margin-top: 450px;
    }

    .equipeSaibamaisResponsivo{
        margin-bottom: 100px;
    }
}

@media only screen and (min-width: 1300px){

    .container{
        width: 1200px;
    }

}